import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

import styles from './ArrowLink.module.scss'

type Props = JSX.IntrinsicElements['a'] & {
  as?: 'a' | 'span'
}
export const ArrowLink = memo<Props>(({ children, as: CustomTag = 'a', ...props }) => {
  return (
    <CustomTag {...props} className={styles.root}>
      {children}
      <FontAwesomeIcon icon={faAngleRight} />
    </CustomTag>
  )
})
