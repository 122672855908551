import type { AnimationConfigWithData, AnimationItem } from 'lottie-web';
import Lottie from 'lottie-web'
import { useEffect, useRef } from 'react'

type UseLottieAnimationProps = {
  animationData: unknown
  speed?: number
} & Omit<AnimationConfigWithData, 'container'>

export const useLottieAnimation = ({
  animationData,
  speed = 1,
  ...override
}: UseLottieAnimationProps) => {
  const lottieRef = useRef<AnimationItem>(null)
  const animationElRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    lottieRef.current = Lottie.loadAnimation({
      container: animationElRef.current,
      animationData,
      loop: true,
      ...override,
    })
    lottieRef.current.setSpeed(speed)

    return () => {
      lottieRef.current.destroy()
    }
  }, [])

  return { animationElRef, lottieRef }
}
