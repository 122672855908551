import type { PropsWithChildren } from 'react'
import { forwardRef, memo } from 'react'

import BgImage from '~/images/Works/bg.png'

type Prop = PropsWithChildren<JSX.IntrinsicElements['section']>

const GridSectionInner = forwardRef<HTMLSelectElement, Prop>(({ children, ...props }, ref) => {
  return (
    <section
      className="relative"
      style={{
        backgroundImage: `url(${BgImage.src})`,
        backgroundSize: `${BgImage.width / 2}px auto`,
      }}
      ref={ref}
      {...props}
    >
      {children}
    </section>
  )
})

export const GridSection = memo<Prop>(GridSectionInner)
