import { microcmsClient } from '@/lib/microcms'

import type { Work } from '../types/work'

type Props = {
  limit: number
  offset?: number
  not_in_id?: string
}
export const getWorks = async (props: Props) => {
  const response = await microcmsClient.getList<Work>({
    endpoint: 'works',
    queries: {
      limit: props.limit,
      offset: props.offset ?? 0,
      filters: props.not_in_id ? `id[not_equals]${props.not_in_id}` : null,
    },
  })

  return response
}
