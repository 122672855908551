import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forwardRef, memo } from 'react'

import styles from './LinkTextWithIcon.module.scss'

type Props = {
  label: string
  icon: any
} & JSX.IntrinsicElements['a']

const LinkTextWithIconInner = forwardRef<HTMLAnchorElement, Props>(
  ({ label, icon, ...props }, ref) => {
    return (
      <a className={styles.root} {...props} ref={ref}>
        <span className={styles.root__label}>
          <FontAwesomeIcon icon={icon} className={styles.root__icon} />
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </span>
        <span>
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
      </a>
    )
  },
)

export const LinkTextWithIcon = memo(LinkTextWithIconInner)
