import { memo } from 'react'

import { useInterSection } from '@/hooks/useInterSection'
import { NextImage } from '@/lib/next'
import BannerImagePC from '~/images/Banner/banner--pc.png'
import BannerImageSP from '~/images/Banner/banner--sp.png'

const alt = 'マーケティングマーケティング支援サービス開始'
const url = 'https://dm.applynow.co.jp/news/78041/'

export const Banner = memo(() => {
  const { ref, isInterSection } = useInterSection()
  return (
    <section className="pt-10 pb-2 md:pt-16 bg-bg-gray">
      <div className="container">
        <div ref={ref} data-animated={isInterSection} className="animate a-fadeInUp text-center">
          <a href={url} className="inline-block transition-all group">
            <div className="md:hidden text-center w-60 relative drop-shadow-md">
              <NextImage
                src={BannerImageSP}
                width={BannerImageSP.width}
                height={BannerImageSP.height}
                alt={alt}
                objectFit="cover"
              />
            </div>
            <div className="hidden md:block max-w-3xl duration-700 group-hover:scale-110 group-hover:drop-shadow-md">
              <NextImage
                src={BannerImagePC}
                width={BannerImagePC.width}
                height={BannerImagePC.height}
                alt={alt}
                objectFit="cover"
                className=""
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  )
})
