import lottie from 'lottie-web'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import waveLottieJson from '@/assets/lottie/wave.json'
import { useCookieAttentionContext } from '@/components/Domains/Cookie/hooks/useCookieAttention'
import { AspectRatio } from '@/components/UIParts/AspectRatio'
import { useInterSection } from '@/hooks/useInterSection'
import { useLottieAnimation } from '@/hooks/useLottieAnimation'
import { ankerMove, useBreakpointValue } from '@/hooks/useUtils'
import ScrollButtonSVG from '~/images/Mainvisual/scrollButton.svg'
import MainVisualCatchLottieData from '~/movie/mainvisual--catch.json'

type MainVisualProps = {}

export const MainVisual = memo<MainVisualProps>(() => {
  const onAnkerLinkClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const targetEl = document.querySelector<HTMLDivElement>(`#service`)
    ankerMove({
      el: targetEl,
      offset: 20,
    })
    return false
  }, [])

  const { ref, isInterSection } = useInterSection()

  const { lottieRef, animationElRef } = useLottieAnimation({
    animationData: waveLottieJson,
    loop: false,
    autoplay: false,
  })

  const scrollElAnimateProp = useMemo(() => {
    return isInterSection ? 'opacity-1' : 'opacity-0'
  }, [isInterSection])

  useEffect(() => {
    lottieRef.current.stop()
    setTimeout(() => {
      if (isInterSection) lottieRef.current.play()
    }, 2000)
  }, [isInterSection, lottieRef])

  return (
    <div className="relative overflow-hidden pb-10 pt-11 md:pt-0" ref={ref}>
      <MVvideoElement />
      <button
        type="button"
        className={`absolute -bottom-4 left-1/2 w-24 -translate-x-12 cursor-pointer transition-all delay-[2000ms] duration-1000 md:bottom-8 md:w-32 md:-translate-x-16 xl:bottom-8 ${scrollElAnimateProp}`}
        onClick={onAnkerLinkClick}
        aria-label="スクロール"
      >
        <div className="animate a-hovering" data-animated={isInterSection}>
          <ScrollButtonSVG />
        </div>
      </button>
      <div
        className="pointer-events-none absolute -bottom-1 left-0 w-full md:-bottom-10"
        ref={animationElRef}
      />
    </div>
  )
})


const MVvideoElement = memo(() => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const LottieRef = useRef(null)
  const LottieAnimationRef = useRef(null)

  const cookieAttention = useCookieAttentionContext()

  const videoSrc = useBreakpointValue(['/movie/mainvisual_sp.mp4', '/movie/mainvisual.mp4'])

  useEffect(() => {
    LottieAnimationRef.current = lottie.loadAnimation({
      container: LottieRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: MainVisualCatchLottieData,
    })
  }, [])

  useEffect(() => {
    cookieAttention.onHide()
    videoRef.current.play()
    videoRef.current.onended = () => LottieAnimationRef.current.play()
    LottieAnimationRef.current.addEventListener('complete', () => {
      cookieAttention.onShow()
    })
  }, [videoSrc])

  return (
    <div className="relative">
      <div className="absolute left-0 top-0 z-10 hidden h-full w-full md:block" ref={LottieRef} />
      <AspectRatio ratio={[750 / 1340, 16 / 9]}>
        <video
          muted={true}
          autoPlay
          src={videoSrc}
          key={videoSrc}
          ref={videoRef}
          playsInline
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
      </AspectRatio>
    </div>
  )
})
