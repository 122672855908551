import type { NextPageWithLayout } from '_types/next'

import { Home } from '@/components/Templates/Home'
import type { GetStaticPropsReturn } from '@/components/Templates/Home/_hook'

export { getStaticProps } from '@/components/Templates/Home/_hook'

const Page: NextPageWithLayout<GetStaticPropsReturn> = (props) => {
  return <Home {...props} />
}

export default Page
