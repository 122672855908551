import { memo } from 'react'

import { ArrowLink } from '@/components/UIParts/ArrowLink'
import { useInterSection } from '@/hooks/useInterSection'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

import type { Work } from '../types/work'

type WorksItem = Work
export const WorksItem = memo<WorksItem>((item) => {
  const { ref, isInterSection } = useInterSection<HTMLAnchorElement>()

  return (
    <NextLink href={pagesPath.works._work_id(item.id).$url()} passHref>
      <a
        className="grid grid-rows-[max-content_1fr] bg-bg-gray cursor-pointer h-full group animate a-fadeInUp"
        data-animated={isInterSection}
        ref={ref}
      >
        <div className="w-full h-0 relative pb-[56.25%] bg-white overflow-hidden">
          <img
            src={item.thumbnail.url}
            width={item.thumbnail.width}
            height={item.thumbnail.height}
            className="absolute top-0 left-0 w-full h-full object-cover duration-700 transition-all group-hover:scale-110"
            alt=""
          />
        </div>
        <div className="p-6 grid grid-rows-[1fr_max-content] min-h-[205px] gap-10">
          <div>
            <div className="font-bold mb-2">
              <span className="text-xs">{item.task_name}</span>
              <h3 className="text-xl">{item.client_name} 様</h3>
            </div>
          </div>
          <ArrowLink as="span">事例の詳細を見る</ArrowLink>
        </div>
      </a>
    </NextLink>
  )
})
