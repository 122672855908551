import { memo } from 'react'

import type { Work } from '../types/work'
import { WorksItem } from './WorksItem'

type Props = {
  items: Work[]
}

export const WorksList = memo<Props>(({ items }) => {
  return (
    <ul className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {items.map((item, index) => {
        return (
          <li key={index}>
            <WorksItem {...item} />
          </li>
        )
      })}
    </ul>
  )
})
