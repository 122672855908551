import { memo } from 'react'

import type { UseHookReturn } from './_hook'
import { About } from './parts/About'
import { Clients } from './parts/Clients'
import { MainVisual } from './parts/MainVisual'
import { NewsSection } from './parts/News'

import { Service } from './parts/Service'
import { Topic } from './parts/Topic'
import { Works } from './parts/Works'
import { Banner } from './parts/Banner'

export const View = memo<UseHookReturn>((props) => {
  return (
    <>
      <MainVisual />
      <Banner />
      <Service />
      <Works items={props.works} />
      <Topic />
      <About />
      <NewsSection items={props.news} />
      <Clients />
    </>
  )
})
