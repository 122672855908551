import { memo } from 'react'

import { FadeContent } from '@/components/UIParts/FadeContent'
import { NextImage } from '@/lib/next'
import LogoANAImage from '~/images/Clients/logo--ana.png'
import LogoDaisoImage from '~/images/Clients/logo--daiso.png'
import LogoKomedaImage from '~/images/Clients/logo--komeda.png'
import LogoPalSystemImage from '~/images/Clients/logo--pal_system.png'

import { GridSection } from '../../_Utils/GridSection'

export const Clients = memo(() => {
  return (
    <GridSection>
      <Client />
    </GridSection>
  )
})

const clientsData = [
  {
    label: 'ANA',
    image: LogoANAImage,
  },
  {
    label: 'Pal System',
    image: LogoPalSystemImage,
  },
  {
    label: 'DAISO',
    image: LogoDaisoImage,
  },
  {
    label: 'Komeda',
    image: LogoKomedaImage,
  },
]

const Client = memo(() => {
  return (
    <FadeContent className="container mx-auto w-full px-3 py-8 ring-bg-gray md:px-28 md:py-14">
      <h2 className="mb-8 text-center text-xl font-bold md:text-2xl">取引先企業</h2>
      <ul className="grid grid-cols-1 justify-items-center gap-0 md:grid-cols-4 md:gap-x-5">
        {clientsData.map((data, index) => {
          return (
            <li key={index} className="w-40 text-center">
              <NextImage
                src={data.image}
                width={data.image.width / 2}
                height={data.image.height / 2}
                alt={data.label}
              />
            </li>
          )
        })}
      </ul>
    </FadeContent>
  )
})
