import { memo } from 'react'

import { NewsList } from '@/components/Domains/News/components/NewsList'
import type { News } from '@/components/Domains/News/types/News'
import { FadeContent } from '@/components/UIParts/FadeContent'
import { Heading } from '@/components/UIParts/Heading/index'

type NewsProps = {
  items: News[]
}

export const NewsSection = memo<NewsProps>(({ items }) => {
  return (
    <section className="bg-bg-gray py-10 pb-0 md:py-[7.5rem]" id="topNews">
      <div className="container mx-auto px-0 md:px-6">
        <FadeContent className="mb-6 md:mb-10">
          <Heading main="News" sub="ニュース 一覧" />
        </FadeContent>
        <div className="bg-white p-10 md:px-20 md:py-16">
          <NewsList items={items} />
        </div>
      </div>
    </section>
  )
})
