import { memo } from 'react'

import { NewsItem } from '@/components/Domains/News/components/NewsItem'
import type { News } from '@/components/Domains/News/types/News'

type Props = {
  items: News[]
}

export const NewsList = memo<Props>(({ items }) => {
  return (
    <ul className="grid gap-y-8 md:gap-y-[3.25rem]">
      {items.map((item, index) => {
        return (
          <li key={index}>
            <NewsItem {...item} />
          </li>
        )
      })}
    </ul>
  )
})
