import type { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import {
  faChartLine,
  faCode,
  faCompassDrafting,
  faFileInvoice,
  faGear,
  faMagnifyingGlass,
  faMobileScreenButton,
  faUserTie,
  faPenNib,
  faCamera,
  faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons'
import { memo } from 'react'

import { Button } from '@/components/UIParts/Button'
import { FadeContent } from '@/components/UIParts/FadeContent'
import { Heading } from '@/components/UIParts/Heading/index'
import { LinkTextWithIcon } from '@/components/UIParts/LinkTextWithIcon'
import { pagesPath } from '@/lib/$path'
import { NextImage, NextLink } from '@/lib/next'
import CreationImage from '~/images/Service/creation.png'
import MarketingImage from '~/images/Service/marketing.png'

export const Service = memo(() => {
  return (
    <div className="relative bg-bg-gray pb-16 pt-10 md:pb-40 md:pt-16" id="service">
      <FadeContent className="mb-4 md:mb-10">
        <Heading main="Service" sub="サービスについて" />
      </FadeContent>
      <section className="mb-16 md:mb-20">
        <Marketing />
      </section>
      <section className="mb-10 md:mb-32">
        <Creation />
      </section>

      <div className="mx-auto w-60">
        <NextLink href={pagesPath.service.$url()} passHref>
          <Button as="a" data-size="medium" data-theme="secondary">
            サービス 一覧
          </Button>
        </NextLink>
      </div>
    </div>
  )
})

const Marketing = memo(() => {
  const points = [
    {
      icon: faFileInvoice,
      label: 'デジタルマーケティング<br />戦略立案',
      href: '#digitalMarketing',
    },
    {
      icon: faMagnifyingGlass,
      label: 'SEO対策',
      href: '#seo',
    },
    {
      icon: faWindowMaximize,
      label: 'オウンドメディア運用代行',
      href: '#ownedMedia',
    },
    {
      icon: faUserTie,
      label: 'SNS運用代行',
      href: '#sns',
    },
    {
      icon: faChartLine,
      label: 'アクセス解析・改善支援',
      href: '#accessAnalysis',
    },
  ]
  return (
    <FadeContent className="container relative grid flex-row-reverse gap-y-8 px-6 pt-20 md:flex">
      <div className="md:w-5/12">
        <NextImage
          src={MarketingImage}
          width={MarketingImage.width}
          height={MarketingImage.height}
          alt=""
          role="presentation"
        />
      </div>

      <div className="w-full md:mb-0 md:w-7/12">
        <div>
          <div className="absolute top-0 z-50 bg-white px-4 pb-4 md:relative md:w-[27rem] md:px-6 md:pb-6 md:pt-4">
            <h2 className="mb-2 font-lato text-4xl leading-snug md:text-56 md:leading-none">
              Marketing
            </h2>
            <span className="block text-sm font-bold md:text-base">
              ビジネスに変化と成長をもたらす
              <br className="block md:hidden" />
              マーケティング支援
            </span>
          </div>
          <div className="mx:py-12 grid gap-x-7 gap-y-10 px-6 md:grid-cols-2 md:px-10 md:pt-16">
            {points.map((point, index) => {
              return <LinkText {...point} key={index} />
            })}
          </div>
        </div>
      </div>
    </FadeContent>
  )
})

const Creation = memo(() => {
  const points = [
    {
      icon: faWindowMaximize,
      label: 'Webサイト制作',
      href: '#websiteProduction',
    },
    {
      icon: faMobileScreenButton,
      label: 'アプリデザイン',
      href: '#applicationDesign',
    },
    {
      icon: faCompassDrafting,
      label: 'UI/UX設計改善',
      href: '#uiux',
    },
    {
      icon: faPenNib,
      label: '記事作成・ライティング',
      href: '#writing',
    },
    {
      icon: faCamera,
      label: 'インタビュー・写真撮影',
      href: '#interview',
    },
    {
      icon: faCode,
      label: 'フロント開発',
      href: '#frontDevelop',
    },
    {
      icon: faGear,
      label: 'CMS構築',
      href: '#cms',
    },
  ]
  return (
    <FadeContent className="container relative grid gap-y-8 px-6 pt-20 md:flex">
      <div className="md:w-5/12">
        <NextImage
          src={CreationImage}
          width={CreationImage.width}
          height={CreationImage.height}
          alt=""
          role="presentation"
        />
      </div>
      <div className="w-full md:mb-0 md:w-7/12">
        <div className="ml-auto mr-1">
          <div className="absolute top-0 z-50 bg-white px-4 pb-4 pt-1 md:relative md:w-9/12 md:px-6 md:pt-4">
            <h2 className="mb-2 font-lato text-4xl leading-snug md:text-56 md:leading-none">
              Creation
            </h2>
            <span className="block text-sm font-bold md:text-base">
              情報設計に基づいた、
              <br className="block md:hidden" />
              ユーザーフレンドリーなクリエイティブ制作
            </span>
          </div>
          <div className="mx:py-12 grid gap-x-7 gap-y-10 px-6 md:grid-cols-2 md:px-10 md:py-16">
            {points.map((point, index) => {
              return <LinkText {...point} key={index} />
            })}
          </div>
        </div>
      </div>
    </FadeContent>
  )
})

type LinkTextProps = {
  label: string
  icon: IconDefinition
  href?: string
}
const LinkText = memo<LinkTextProps>(({ label, icon, href }) => {
  return (
    <NextLink href={pagesPath.$url({ hash: `/service/${href}` }).hash} passHref>
      <LinkTextWithIcon icon={icon} label={label} />
    </NextLink>
  )
})
