import { microcmsClient } from '@/lib/microcms'

import type { News } from '../types/News'

type Props = {
  limit: number
  offset?: number
  not_in_id?: string
}

export const getNews = async (props: Props) => {
  const response = await microcmsClient.getList<News>({
    endpoint: 'news',
    queries: {
      limit: props.limit,
      offset: props.offset ?? 0,
      filters: props.not_in_id ? `id[not_equals]${props.not_in_id}` : null,
    },
  })

  return response
}
