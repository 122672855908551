import { memo } from 'react'

import { WorksList } from '@/components/Domains/Works/components/WorksList'
import type { Work } from '@/components/Domains/Works/types/work'
import { Button } from '@/components/UIParts/Button'
import { Heading } from '@/components/UIParts/Heading'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

import { GridSection } from '../../_Utils/GridSection'

type Props = {
  items: Work[]
}
export const Works = memo<Props>(({ items }) => {
  return (
    <GridSection className="pb-32 pt-10 md:pb-64 md:pt-24">
      <div className="container">
        <Heading main="Works" sub="実績" className="mb-6 md:mb-10" />
        <WorksList items={items} />
        <div className="mt-10">
          <div className="mx-auto w-60">
            <NextLink href={pagesPath.works.$url()} passHref>
              <Button as="a" size="medium" theme="secondary">
                実績 一覧
              </Button>
            </NextLink>
          </div>
        </div>
      </div>
    </GridSection>
  )
})
