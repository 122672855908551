import { memo } from 'react'

import { Button } from '@/components/UIParts/Button'
import { FadeContent } from '@/components/UIParts/FadeContent'
import { NextImage, NextLink } from '@/lib/next'
import AboutImage from '~/images/Company/motif--home.png'
import BgWaveImage from '~/images/Utils/bg_wave--white.png'

export const About = memo(() => {
  return (
    <section className="relative pb-16 md:pb-0">
      <div
        style={{
          backgroundImage: `url(${BgWaveImage.src})`,
          backgroundSize: `cover`,
          backgroundRepeat: 'no-repeat',
        }}
        className="absolute -top-8 h-9 w-full md:-top-14 md:h-[8.25rem]"
      />
      <div className="bg-white px-6 pt-10 md:px-0 md:pb-14 md:pt-[4.5rem]">
        <div className="container grid flex-row-reverse gap-x-6 md:flex">
          <FadeContent className="md:w-1/2 md:px-12">
            <NextImage src={AboutImage} alt="" role="presentation" />
          </FadeContent>
          <FadeContent className="md:w-1/2" id="aboutus">
            <div>
              <h2 className="inline-block bg-bg-gray px-6 pt-3 text-4xl leading-snug md:text-56">
                About us
              </h2>
              <span className="block bg-bg-gray px-6 text-sm font-bold md:text-base">
                私たちについて
              </span>
            </div>
            <div className="mb-10 block bg-bg-gray p-6 py-4 text-base leading-loose md:text-[1.125rem]">
              <p className="mb-2">
              「デジタルとクリエイティブで未来を変革し、貢献する」をミッションに、顧客がビジネスにかける想いやビジョンを形にしていきます。
              </p>
            </div>
            <div className="mx-auto w-[16rem] md:ml-0">
              <NextLink href="https://applynow.co.jp/company" passHref>
                <Button as="a" data-size="medium" data-theme="secondary">
                  詳しく見る
                </Button>
              </NextLink>
            </div>
          </FadeContent>
        </div>
      </div>
    </section>
  )
})
