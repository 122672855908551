import dayjs from 'dayjs'
import { memo, useMemo } from 'react'

import type { News } from '@/components/Domains/News/types/News'
import { useInterSection } from '@/hooks/useInterSection'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

import styles from './NewsItem.module.scss'

type NewsItem = News

export const NewsItem = memo<NewsItem>((item) => {
  const { ref, isInterSection } = useInterSection<HTMLAnchorElement>()
  const formatDate = dayjs(item.publishedAt).format('YYYY.MM.DD')
  const category = useMemo(() => {
    switch (item.category[0]) {
      case 'お知らせ':
        return {
          label: 'お知らせ',
          slug: 'information',
        }
      case 'プレスリリース':
        return {
          label: 'プレスリリース',
          slug: 'pressRelease',
        }
      case 'セミナー情報':
        return {
          label: 'セミナー情報',
          slug: 'seminar',
        }
      case '重要なお知らせ':
        return {
          label: '重要なお知らせ',
          slug: 'important',
        }
    }
  }, [item.category])

  return (
    <NextLink href={pagesPath.news._news_id(item.id).$url()} passHref>
      <a ref={ref} data-animated={isInterSection} className={styles['news']}>
        <div className={styles['news__content']}>
          <span className={styles['news__tag']} data-category={category.slug}>
            <span>{category.label}</span>
          </span>
          <time dateTime={item.publishedAt} className={styles['news__date']}>
            {formatDate}
          </time>
        </div>
        <div className={styles['news__title']}>{item.title}</div>
      </a>
    </NextLink>
  )
})
