import dayjs from 'dayjs'
import type { InferGetStaticPropsType } from 'next'
import { useEffect, useMemo, useState } from 'react'

import { getNews } from '@/components/Domains/News/api/getNews'
import { getWorks } from '@/components/Domains/Works/api/getWorks'
import { metaFactory } from '@/hooks/useMeta'

export type GetStaticPropsReturn = InferGetStaticPropsType<typeof getStaticProps>
export const getStaticProps = async () => {
  const works = await getWorks({
    limit: 3,
    offset: 0,
  })

  const news = await getNews({
    limit: 5,
    offset: 0,
  })

  const meta = metaFactory({})

  return {
    props: {
      works: works.contents,
      news: news.contents,
      meta,
      isDisplayTopics: false,
      autoDisplayCookieAttention: false,
    },
    revalidate: 60 * 60 * 24,
  }
}

export type UseHookReturn = ReturnType<typeof useHook>

export const useHook = (props: GetStaticPropsReturn) => {
  const [isShow, setState] = useState(true)

  const [isDisplayOpeningAnimation, setIsDisplayOpeningAnimation] = useState(false)

  useEffect(() => {
    const keyName = 'animationLastPlayedAt'
    const lastPlayedAt = localStorage.getItem(keyName)
    const currentTime = dayjs().format('YYYY-MM-DDTHH:mm:ssZ')

    if (lastPlayedAt) {
      const after24 = dayjs(lastPlayedAt).add(24, 'h').format('YYYY-MM-DDTHH:mm:ssZ')
      const isAfter = dayjs(currentTime).isAfter(after24)
      if (isAfter) {
        localStorage.setItem(keyName, currentTime)
        setIsDisplayOpeningAnimation(false)
      }
    } else {
      localStorage.setItem(keyName, currentTime)
      setIsDisplayOpeningAnimation(true)
    }
  }, [])

  const openingAnimationState = {
    isDisplayOpeningAnimation,
    animationState: useState(false),
  }

  return {
    ...props,
    isShow,
    openingAnimationState,
    setState,
    isDisplayOpeningAnimation,
  }
}
